import React from 'react';
import { Link, /* StaticQuery, */ graphql } from 'gatsby';
import Layout from 'components/Layout';
// import { Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import logger from 'utils/logger';
import Img from 'components/Elements/Img';
import numeral from 'numeral';
import _ from 'lodash';
import Fuse from 'fuse.js';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactPlayer from 'react-player';
import { countries, lookup, languages } from 'country-data';

import { Container, Row, Col } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';
import SEO from 'components/SEO/SEO';
import Carousel from 'nuka-carousel';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import YouTube from 'react-youtube';
// import { StickyContainer, Sticky } from 'react-sticky';

// import ShareBar from 'components/CTAs/ShareBar';
import { Sticky } from 'react-sticky';
import LoadingView from 'components/Loaders/LoadingView';
import NavCategories from 'components/Navigation/NavCategories';
import TSharpBoards from 'components/ProductsTemplates/TSharpBoards';
import Header from 'components/Headers/Header';
// import Header from 'components/Headers/Header';
// import ProductCompare from 'components/ProductsTemplates/ProductCompare';
// import { CardProduct } from 'components/Cards/Cards';

class ProductSeriesSingle extends React.Component {
  render () {
    logger.template(__filename, '>', 'render()');
    const siteMetadata = get(this, 'props.data.site.siteMetadata');
    const data = get(this, 'props.data');
    logger.gql(data);
    if (!data || !data.contentfulProductSeries) { return <LoadingView />; }
    const productSeries = data.contentfulProductSeries;
    const productLine = productSeries.productLine && productSeries.productLine;
    // const parsed = querystring.parse(location.search);
    // logger.debug(`querystring`, parsed);

    const gutter = 8;
    // const sliderSettings = {
    //   dots: true,
    //   infinite: false,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   adaptiveHeight: false,
    //   autoplay: true
    // };
    const carouselProps = {
      autoplay: true,
      autoplayInterval: 6000,
      // transitionMode: `fade`,
      autoGenerateStyleTag: false,
      renderCenterLeftControls: ({ previousSlide }) => (
        <button className={`fs-15em prl-10em pointer button-clear`} onClick={previousSlide}>
          <i className="icon icon-Previous" />
        </button>
      ),
      renderCenterRightControls: ({ nextSlide }) => (
        <button className={`fs-15em prl-10em pointer button-clear`} onClick={nextSlide}>
          <i className="icon icon-Next2" />
        </button>
      ),
      wrapAround: true
    };

    const { products } = productSeries;

    // if (productSeries.products) {
    //   productSeries.products.forEach((product) => {
    //     if (product) {
    //       if (product.description) {
    //         logger.success('product.description');
    //         products.push(product);
    //       }
    //     }
    //   });
    // }
    logger.data(products);

    const youtubeOptions = {
      height: '390',
      width: '640',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        disablekb: 1,
        color: `white`,
        controls: 0
      }
    };

    let { slider } = productSeries;

    if (!slider && productSeries.thumbnail) {
      slider = [productSeries.thumbnail];
    }
    logger.verbose('Series slider', slider);

    // const downloadsProductLine = data.contentfulProductLine.downloads;

    // logger.verbose('Series downloadsProductLine', downloadsProductLine);

    const downloadsLine = data.contentfulProductLine.fields.uniqueDownloads;
    logger.verbose('Series downloadsLine data.contentfulProductLine.fields.uniqueDownloads ', downloadsLine);

    // const downloadsLine = [];
    // downloadsProductLine.forEach((downloadProductLine) => {
    //   // logger.debug('Series forEach downloadProductLine', downloadProductLine);
    //   let n = 0;
    //   if (Array.isArray(downloadProductLine.related)) {
    //     downloadProductLine.related.forEach((related) => {
    //       if (
    //         related.__typename === `ContentfulProductSeries` ||
    //         related.__typename === `ContentfulProduct`
    //       ) {
    //         logger.fail('Series forEach skip related.__typename', related.__typename);
    //         n += 1;
    //       } else {
    //         logger.success('Series forEach push related.__typename', related.__typename);
    //       }
    //     });
    //   }
    //   if (n === 0) {
    //     downloadsLine.push(downloadProductLine);
    //   }
    // });
    // logger.verbose('Series downloadsLine', downloadsLine);

    const downloadsResources = productSeries.downloads;
    const { downloadsReference } = productSeries;
    const downloadsProducts = [];
    if (products) {
      products.forEach((product) => {
        if (product.downloads) {
          if (Array.isArray(product.downloads)) {
            product.downloads.forEach((dowload) => {
              downloadsProducts.push(dowload);
            });
          }
        }
      });
    }
    let downloadsConcat = [];

    let downloads = [];
    if (typeof window !== 'undefined') {
      if (Array.isArray(downloadsResources)) {
        logger.debug(`downloadsResources is array`, downloadsResources);
        downloadsConcat = _.concat(downloadsConcat, downloadsResources);
      }
      if (Array.isArray(downloadsReference)) {
        logger.debug(`downloadsReference is array`, downloadsReference);
        downloadsConcat = _.concat(downloadsConcat, downloadsReference);
      }
      if (Array.isArray(downloadsProducts)) {
        logger.debug(`downloadsProducts is array`, downloadsProducts);
        downloadsConcat = _.concat(downloadsConcat, downloadsProducts);
      }
      if (Array.isArray(downloadsLine)) {
        logger.debug(`downloadsLine is array`, downloadsLine);
        downloadsConcat = _.concat(downloadsConcat, downloadsLine);
      }
      logger.debug(`downloadsConcat`, downloadsConcat);
      downloads = _.uniqBy([...downloadsConcat], `id`);
    }
    logger.debug(`downloadsConcat uniqBy`, downloadsConcat);

    const downloadTypesBase = [
      'catalog',
      'datasheet',
      'manual',
      'support',
      'software',
      'document',
      'warranty',
      'case-study',
      'recommended-hardware',
      'media',
      'drawing'
    ];
    const downloadTypesLabels = {
      catalog: 'Catalogs',
      datasheet: 'Datasheets',
      manual: 'Manuals',
      support: 'Support Documents',
      software: 'Software',
      document: 'Other Documents',
      warranty: 'Warranty',
      'case-study': 'Case Studies',
      'recommended-hardware': 'Recommended Hardware',
      media: 'Product Images & Videos',
      drawing: 'Drawings'
    };
    const downloadTypesDynamic = [];
    if (downloads.length > 0) {
      downloads.forEach((download) => {
        if (download) {
          logger.debug('download', download);
          if (!downloadTypesDynamic.includes(download.type)) {
            downloadTypesDynamic.push(download.type);
          }
        }
      });
    }
    const downloadTypes = _.intersection(downloadTypesBase, downloadTypesDynamic);
    logger.verbose('Series downloads', downloads);
    logger.verbose('Series downloadTypes', downloadTypes);
    logger.verbose('Series productSeries.downloads', productSeries.downloads);

    let specifications = productSeries.specifications && (
      productSeries.specifications.specifications && (
        productSeries.specifications.specifications.length > 0 && (
          productSeries.specifications.specifications
        )
      )
    );

    if (!specifications) { specifications = []; }

    if (
      specifications.length < 1 ||
      !specifications[0].row ||
      !specifications[0].data[0].key ||
      !specifications[0].data[0].values[0]
    ) {
      specifications = [];
    }
    logger.verbose('specifications', specifications);

    if (products) {
      products.forEach((product) => {
        if (product) {
          logger.verbose('product', product);
          logger.verbose('product specifications', product.specifications);
          if (product.specifications) {
            if (product.specifications.specifications.length > 0) {
              logger.verbose('push specifications', product.specifications.specifications);
              product.specifications.specifications.forEach((specification) => {
                specifications.push(specification);
              });
            }
          }
          // if (productSpecifications) {
          //   specifications.concat(productSpecifications);
          // }
        }
      });
    }

    specifications = _.uniqBy(specifications, `row`);
    logger.verbose('concat specifications', specifications);
    if (
      specifications.length < 1
    ) {
      specifications = null;
    }

    // const presentation = productSeries.presentation && (
    //   productSeries.presentation.presentation && (
    //     productSeries.presentation.presentation.length > 0 && (
    //       productSeries.presentation.presentation
    //     )
    //   )
    // );
    const boards = productSeries.presentation && (
      productSeries.presentation.boards && (
        productSeries.presentation.boards.length > 0 && (
          productSeries.presentation.boards
        )
      )
    );
    // const keyFeatures = productSeries.presentation && (
    //   productSeries.presentation.keyFeatures && (
    //     productSeries.presentation.keyFeatures.length > 0 && (
    //       productSeries.presentation.keyFeatures
    //     )
    //   )
    // );
    // const complementaryFeatures = productSeries.presentation && (
    //   productSeries.presentation.complementaryFeatures && (
    //     productSeries.presentation.complementaryFeatures.length > 0 && (
    //       productSeries.presentation.complementaryFeatures
    //     )
    //   )
    // );
    let k = 0;

    const firstSlide = slider && (slider[0].file.contentType.split(`/`).pop());
    const sliderBg = firstSlide !== `png` ? `white` : `gray-cold-100`;
    const entryTitle = `${productLine && productLine.name} ${productSeries && productSeries.name}`;
    logger.verbose('boards', boards);
    const otherCategories = [
      `all`
      // `video-walls`,
      // `openGear`,
      // `extenders-receivers`,
      // `matrices-switches`,
      // `converters`,
      // `splitters`
    ];
    const fuse = new Fuse(data.allContentfulCategory.edges, {
      keys: ['node.contentTypes']
    });
    const categoriesData = fuse.search(`productGroups`);
    const sliderSize = `40vh`;

    const { presentationBlocks } = productSeries;
    logger.verbose('presentationBlocks', presentationBlocks);

    const videoAssets = [];
    if (Array.isArray(downloads)) {
      downloads.forEach((item) => {
        logger.debug('downloadsConcat.forEach((item)', item);
        if (item.resource) {
          if (item.resource.file.contentType === `video/mp4`) {
            videoAssets.push(item);
          }
        }
      }); 
    }
    logger.verbose('videoAssets >>>', videoAssets);

    logger.templateEnd();
    // logger.verbose(`countries`, countries);
    // logger.verbose(`languages`, languages);

    const headerProps = {
      height: `70vh`,
      bgColor: `gray-cold-100`,
      ui: 90,
    };
    return (
      productSeries.eol ? (
        <Layout>
          <Fluid>
            <SEO
              title={`No longer available - ${entryTitle}`}
              description={productSeries.summary && productSeries.summary.summary}
              image={productSeries.thumbnail && productSeries.thumbnail.fixed.src}
              pathname={`/products/${productLine ? productLine.slug : `l`}/${productSeries.slug}`}
              type={`product`}
            />
            <Header {... headerProps}>
              <Offset>
                <h1 className={`lh-10 ls--10em mb-3em fw-300`}><span className={`fw-800 fs-08em`}>{productLine && productLine.name}</span><br/><span className={`fw-300 fs-25em`}>{productSeries.name}</span></h1>
                <p className="lead fs-20em">Is no longer available.</p>
                <p className={`pb-20em`}>Perhaps you would like to check our current <Link style={{ fontWeight: `600` }} to={`/product`}>product lines</Link>.</p>
              </Offset>
            </Header>
            <section className={`bg-indigo-900 ptb-30em prl-${gutter / 2}px`}>
              <Container fluid>
                <Row className={`text-center`}>
                  <Col xs={12} lg={11} xl={10} className={`mrl-auto`}>
                    <h1 className={`mrl-auto section-title fs-18em ls--15em mb-15em`}>Downloads & Resources</h1>
                    <hr className={`alpha-30 bg-gray-cold-400`} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={11} lg={11} xl={10} className={`mrl-auto`}>
                    <Row>
                      {downloadTypes && downloadTypes.map((type) => {
                        const label = downloadTypesLabels[type] || <span style={{ textTransform: `capitalize` }}>{type.replace('-', ' ')}</span>;
                        return (
                          <Col key={`downloads-${type}`} md={6} lg={3} xl={50} className={`mb-10em`}>
                            <h1 className="fs-10em fw-400 lh-12 mb-10em">{label}</h1>
                            <hr className={`alpha-30 bg-gray-cold-400`} />
                            <ul className={`related-resources uls-none ml-0 pl-0`}>
                              { downloads.length > 0 && (
                                downloads.sort((a, b) => {
                                  if (a && b) {
                                    if (a.sort < b.sort) { return -1; }
                                    if (a.sort > b.sort) { return 1; }
                                  }
                                  return 0;
                                }).sort((a, b) => {
                                  if (a && b) {
                                    if (a.type < b.type) { return -1; }
                                    if (a.type > b.type) { return 1; }
                                  }
                                  return 0;
                                }).map((download) => {
                                  if (download) {
                                    if (download.resource) {
                                      const downloadType = download.type;
                                      let icon;
                                      if (downloadType === `datasheet`) {
                                        icon = `File-Chart`;
                                      } else if (
                                        downloadType === `manual` ||
                                        downloadType === `support`
                                      ) {
                                        icon = `Book`;
                                      } else if (downloadType === `catalog`) {
                                        icon = `Address-Book2`;
                                      } else {
                                        icon = `Download-fromCloud`;
                                      }
                                      let extension = download.resource.file.contentType.split(`/`).pop();
                                      if (extension === `x-dosexec`) { extension = `Executable`; }
                                      if (extension === `octet-stream`) { extension = `Executable`; }
                                      const urlSplit = download.resource.file.url.split('/');
                                      const prettyURL = urlSplit[urlSplit.length - 1];
                                      let { name } = download;
                                      let matches;
                                      let lang;
                                      let language;
                                      let flag;
                                      if (download.name) {
                                        matches = download.name.includes('(') && download.name.split('(');
                                        lang = matches && matches[1].split(')')[0];
                                        if (matches) {
                                          name = matches[0];
                                        }
                                      }
                                      if (lang) {
                                        language = lookup.languages({ name: lang })[0];
                                        if (language) {
                                          flag = lookup.countries({ languages: language.alpha3 })[0] && lookup.countries({ languages: language.alpha3 })[0].alpha2.toLowerCase();
                                          logger.debug(`language`, language, `flag`, flag);
                                        }
                                      }
                                      // const country = lookup.countries({ alpha2: country })[0] }
                                      let description;
                                      logger.debug(`download.resource.file`, download.resource.file);
                                      if (download.resource.description) {
                                        description = download.resource.description;
                                      }

                                      return (
                                        download.resource.file && downloadType === type ? (
                                          <li key={`download-${download.id}`} className={`mb-10em`}>
                                            <a
                                              href={`/downloads/files/${prettyURL}`}
                                              alt={download.resource.file.fileName}
                                              target={`_blank`}
                                              className={`color-gray-cold-300 color-white-hover`}
                                            >
                                              <Container>
                                                <Row>
                                                  <Col xs={1} className={`prl-0`}>
                                                    <i className={`icon icon-${icon} fs-15em mr-5em color-primary-500`} />
                                                  </Col>
                                                  <Col xs={11} className={`pl-10em fs-09em`}>
                                                    <span>
                                                      <b>{name}</b>
                                                      {flag && (<b> <i className={`flag-icon flag-icon-${flag}`} style={{ top: `-1px`, display: `inline-block`, marginLeft: `5px`, fontSize: `12px` }} /></b>)}
                                                    </span>
                                                    <br />
                                                    <span className={`alpha-70 fs-08em tt-uppercase`}>
                                                      {extension.toLowerCase() === 'executable' ? 'Application' : extension}
                                                      <span style={{ top: `-1px`, display: `inline-block`, padding: `0 8px`} }> | </span>
                                                      {numeral(download.resource.file.details.size).format('0.0 b')}
                                                      {flag && language && (
                                                        <span style={{ top: `-1px`, display: `inline-block`, padding: `0 8px`} }> | </span>
                                                      )}
                                                    </span>
                                                    {flag && language && (
                                                      <span className={`alpha-70 fs-08em`}>
                                                        {language.name}
                                                      </span>
                                                    )}
                                                    {description && (
                                                      <span className={`fs-08em mt-05em`}>
                                                        <br />
                                                        <span style={{
                                                          top: `0`,
                                                          fontSize: `13px`,
                                                          lineHeight: `1.2`,
                                                          display: `block`,
                                                          paddingTop: `2px`,
                                                          textTransform: `none !important`,
                                                          color: `rgba(255,255,255,0.5)`
                                                        }}>
                                                          {description}
                                                        </span>
                                                      </span>
                                                    )}
                                                  </Col>
                                                </Row>
                                              </Container>
                                            </a>
                                          </li>
                                        ) : (``)
                                      );
                                    }
                                  }
                                  return ``;
                                })
                              )}
                            </ul>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
          </Fluid>
        </Layout>
      ) : (
        <Layout>
          <Fluid>
            <SEO
              title={entryTitle}
              description={productSeries.summary && productSeries.summary.summary}
              image={productSeries.thumbnail && productSeries.thumbnail.fixed.src}
              pathname={`/products/${productLine ? productLine.slug : `l`}/${productSeries.slug}`}
              type={`product`}
            />
            <nav>
              <Sticky topOffset={46}>
                {({ style, isSticky }) => (
                  <div className={`z-300 transitionSticky ${isSticky ? `isSticky` : `notSticky`}`} style={style}>
                    <NavCategories otherCategories={otherCategories} navigate={`/products`} categories={categoriesData} className={`bg-indigo-600`} />
                  </div>
                )}
              </Sticky>
            </nav>
            <article style={{ overflowX: `hidden` }}>
              <Container fluid className={`prl-0`}>
                <Tabs>
                  <div style={{ position: `relative`}}>
                    <PerfectScrollbar className={`ps-hide-y tabs-scroll`}>
                      <TabList>
                        <Tab>Overview</Tab>
                        {products && <Tab>{products.length > 1 ? `Models` : `Details`}</Tab>}
                        { productSeries.slug === `TSharp` && (
                          productSeries.platformBoardsTab && <Tab>Platform Boards</Tab>
                        )}
                        { specifications && (
                          specifications[0].row && (
                            <Tab>Specifications</Tab>
                          )
                        )}
                      </TabList>
                    </PerfectScrollbar>
                  </div>
                  <TabPanel>
                    <section id="overview" className={`bg-${sliderBg} text-center overflow-hidden`}>
                      <Row className={`pos-relative introSlider ptb-20em align-items-center`}>
                        { slider.length > 1 ? (
                          <Carousel {...carouselProps} autoplay wrapAround>
                            {
                              slider.map((image) => {
                                return (
                                  <Col key={`productSliderIntro-${image.fluid.src}`} className={`mc-offset ptb-30em`} style={{ height: sliderSize, minHeight: sliderSize }}>
                                    <div>
                                      <Img
                                        title={`${image.title}`}
                                        alt={entryTitle}
                                        className={`${sliderBg !== `white` && `imageReflectChild`}`}
                                        sizes={image.fluid}
                                        backgroundColor={`${sliderBg === `white` ? `#ffffff` : `#f1f0f7`}`}
                                        position={`relative`}
                                        style={
                                          {
                                            top: `-2em`,
                                            maxHeight: sliderSize,
                                            overflow: `visible`
                                          }
                                        }
                                        imgStyle={
                                          {
                                            height: `100%`,
                                            maxWidth: `70vw`,
                                            left: `50%`,
                                            bottom: `0`,
                                            transform: `translateX(-50%)`,
                                            maxHeight: sliderSize
                                          }
                                        }
                                        objFit={`contain`}
                                        objPosition={`50% 100%`}
                                        fadeIn={false}
                                      />
                                    </div>
                                  </Col>
                                );
                              })
                            }
                          </Carousel>
                        ) : (
                          <Col key={`productSliderIntro-${slider[0].fluid.src}`} className={`mc-offset ptb-30em`} style={{ height: sliderSize, minHeight: sliderSize }}>
                            <Img
                              title={`${slider[0].title}`}
                              alt={entryTitle}
                              className={`${sliderBg !== `white` && `imageReflectChild`}`}
                              sizes={slider[0].fluid}
                              backgroundColor={`${sliderBg === `white` ? `#ffffff` : `#f1f0f7`}`}
                              position={`relative`}
                              style={
                                {
                                  maxHeight: sliderSize,
                                  overflow: `visible`
                                }
                              }
                              imgStyle={
                                {
                                  height: `100%`,
                                  maxWidth: `70vw`,
                                  left: `50%`,
                                  bottom: `0`,
                                  transform: `translateX(-50%)`,
                                  maxHeight: sliderSize
                                }
                              }
                              objFit={`contain`}
                              objectPosition={`50% 100%`}
                              fadeIn={false}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row className={`align-items-center`}>
                        <Col className={`mc-offset pb-30em`}>
                          <h1 className={`fs-30em ls--30em pt-5em mb-3em pos-relative`} style={{ zIndex: `1` }}><span className={`fw-800`}>{productLine && productLine.name}</span> <span className={`fw-400`}>{productSeries.name}</span></h1>
                          {productSeries && <p className={`fs-20em ff-sans fw-300 mb-5em`}>{productSeries.tagline}</p>}
                          {productSeries && productSeries.summary && <p className={`lh-16 fs-095em alpha-70 mb-15em`}>{productSeries.summary.summary}</p>}
                          <Link to={`/buy`} className={`btn btn-outline-primary-500 btn-xs ptb-9em mb-20em color-primary-500 color-primary-800-hover`} title={`Find a Distributor`} itemProp="url" >Find a Distributor</Link>
                        </Col>
                      </Row>
                    </section>
                    {presentationBlocks && (
                      presentationBlocks.length > 0 && (
                        <section id="presentation">
                          {presentationBlocks.map((block) => {
                            if (block.layout === `Right Image Feature` || block.layout === `Left Image Feature`) {
                              const posImage = block.layout === `Right Image Feature` ? 1 : 2;
                              const posContent = block.layout === `Right Image Feature` ? 2 : 1;
                              return (
                                <Row key={block.id} className={`ptb-0 bt-1 align-items-center ${block.bgColor ? `bg-${block.bgColor}` : ``} ${block.layout === `Right Image Feature` ? `odd` : `even`}`}>
                                  <Col md={{ size: 6, order: posImage }} className={`image-element align-self-stretch`}>
                                    <Img
                                      title={`${block.title}`}
                                      alt={`${productLine && productLine.name} ${productSeries.name} ${block.title}`}
                                      fluid={block.featuredImage && block.featuredImage.fluid}
                                      style={
                                        {
                                          height: `100%`,
                                          width: `100%`
                                        }
                                      }
                                      fadeIn
                                    />
                                  </Col>
                                  <Col xs={12} md={{ size: 6, order: posContent }} className={`prl-20em ptb-30em`}>
                                    <Col className={`mc-offset pos-relative`}>
                                      <h1 className={`section-title fs-18em ls--15em mb-0`}>{block.title}</h1>
                                      <hr className={`alpha-40`} />
                                      {block.content && <div className={`product-keyFeature fs-10em lh-16`} dangerouslySetInnerHTML={{ __html: block.content.childMarkdownRemark.html }} />}
                                    </Col>
                                  </Col>
                                </Row>
                              );
                            } else if (block.layout === `3 Columns` || block.layout === `4 Columns`) {
                              const md = block.content.childMarkdownRemark.html;
                              const columns = md.split(`<hr>`);
                              logger.verbose(`columns`, columns);

                              let layout;
                              if (block.layout === `1 Columns`) {
                                layout = {
                                  md: 12,
                                  lg: 12,
                                  xl: 12
                                };
                              } else if (block.layout === `2 Columns`) {
                                layout = {
                                  md: 12,
                                  lg: 6,
                                  xl: 6
                                };
                              } else if (block.layout === `3 Columns`) {
                                layout = {
                                  md: 2,
                                  lg: 4,
                                  xl: 4
                                };
                              } else if (block.layout === `4 Columns`) {
                                layout = {
                                  md: 6,
                                  lg: 3,
                                  xl: 3
                                };
                              } else if (block.layout === `5 Columns`) {
                                layout = {
                                  md: 12,
                                  lg: 50,
                                  xl: 50
                                };
                              } else {
                                layout = {
                                  md: 12,
                                  lg: 12,
                                  xl: 12
                                };
                              }
                              let c = 0;
                              return (
                                <Offset key={block.id}>
                                  <Row className={`ptb-15em text-center`}>
                                    {block.title &&
                                      <Col xs={12} lg={11} xl={10} className={`mrl-auto`}>
                                        <h1 className={`mrl-auto section-title fs-18em ls--15em mb-15em`}>{block.title}</h1>
                                        <hr className={`alpha-40`} />
                                        { block.featuredImage && (
                                          <Img
                                            title={`${block.title}`}
                                            alt={`${productLine && productLine.name} ${productSeries.name} ${block.title}`}
                                            fluid={block.featuredImage && block.featuredImage.fluid}
                                            style={
                                              {
                                                width: `100%`,
                                                maxWidth: `980px`,
                                                left: `50%`,
                                                transform: `translateX(-50%)`
                                              }
                                            }
                                          />
                                        )}
                                      </Col>
                                    }
                                    <div className="w-100 d-none d-md-block" />
                                    {columns && columns.map((column) => {
                                      c += 1;
                                      return (
                                        <Col key={`${block.id}-${c}`} md={layout.md} lg={layout.lg} xl={layout.xl}>
                                          {column && <div className={`product-col-features ptb-20em prl-10em text-center`} dangerouslySetInnerHTML={{ __html: column }} />}
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </Offset>
                              );
                            }
                            return (
                              <Offset key={block.id}>
                                <Row className={`pt-30em pb-20em text-center`}>
                                  <Col xs={12} className={`mrl-auto`}>
                                    <h1 className={`mrl-auto section-title fs-18em ls--15em mb-15em`}>{block.title}</h1>
                                    <hr className={`alpha-40`} />
                                  </Col>
                                  <Col xs={12} lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} className={`text-center mrl-auto`}>
                                    {block.content &&
                                      <div
                                        className={`product-keyFeature fs-10em lh-16`}
                                        dangerouslySetInnerHTML={{ __html: block.content.childMarkdownRemark.html }}
                                      />
                                    }
                                  </Col>
                                </Row>
                              </Offset>
                            );
                          })}
                        </section>
                      )
                    )}
                  </TabPanel>
                  { products && (
                    <TabPanel>
                      <section id="productFeatures">
                        {
                          products.length > 1 &&
                          <Row>
                            <Col className={`mc-offset`}>
                              <h1 className={`mrl-auto section-title fs-18em ls--15em pt-15em mb-15em`}>
                                <span>{productSeries.name}</span>
                              </h1>
                              <hr className={`alpha-40`} />
                            </Col>
                          </Row>
                        }
                        <Col className={`mc-offset`}>
                          { products.length > 0 &&
                            products.map((product) => {
                              return (
                                product && !product.eol && (
                                  <Row key={`productSeries-product-${product.id}`} className={`${products.length < 2 && `pt-30em`}`}>
                                    <Col xs={12}>
                                      <h1 className={`mb-0 mb-0 fs-20em`}>{products.length > 1 ? product.name : <span>{productSeries.name}</span>}</h1>
                                      <h2 className={`fs-12em mb-20em fw-300 lh-14`}>{product.tagline}</h2>
                                      <hr className={`alpha-40`} />
                                    </Col>
                                    <Col md={6} lg={5} className={`ptb-30em`}>
                                      {
                                        product.images &&
                                          product.images.length > 1 ? (
                                            <Carousel {...carouselProps}>
                                              {
                                                product.images.map((image) => {
                                                  return (
                                                    <div key={`sliderimages-legacy-${image}`} className={`prl-50em text-center pos-relative`} style={{ height: '400px' }}>
                                                      <img src={image} alt={product.name} className={`y-center`} />
                                                    </div>
                                                  );
                                                })
                                              }
                                            </Carousel>
                                          ) : (
                                            product.thumbnail && (
                                              <Img
                                                title={`${product.name}`}
                                                alt={`${productLine && productLine.name} ${product.name}`}
                                                fluid={product.thumbnail && product.thumbnail.fluid}
                                                backgroundColor={`#fafafa`}
                                                position={`relative`}
                                                style={
                                                  {
                                                    // height: `50vh`
                                                  }
                                                }
                                                imgStyle={
                                                  {
                                                    objectFit: `contain`,
                                                    top: `50%`,
                                                    transform: `translateY(-50%)`
                                                  }
                                                }
                                                fadeIn
                                              />
                                            )
                                          )
                                      }
                                      {
                                        product.youtube && (
                                          product.youtube.length > 0 && (
                                            <Carousel {...carouselProps}>
                                              {
                                                product.youtube.map((video) => {
                                                  return (
                                                    product.youtube && (
                                                      <div key={`slideryoutube-legacy-${video}`}>
                                                        <YouTube
                                                          videoId={product.youtube}
                                                          opts={youtubeOptions}
                                                        />
                                                      </div>
                                                    )
                                                  );
                                                })
                                              }
                                            </Carousel>
                                          )
                                        )
                                      }
                                    </Col>
                                    <Col md={6} lg={{ size: 6, offset: 1 }} className={`ptb-30em`}>
                                      {product.description && <div className={`product-legacy fs-095em lh-18 pb-20em`} dangerouslySetInnerHTML={{ __html: product.description.childMarkdownRemark.html }} />}
                                    </Col>
                                  </Row>
                                )
                              );
                            })
                          }
                        </Col>
                      </section>
                    </TabPanel>
                  )}
                  { productSeries.slug === `TSharp` && (
                    productSeries.platformBoardsTab && (
                      <TabPanel>
                        <TSharpBoards />
                      </TabPanel>
                    )
                  )}
                  { specifications && (
                    specifications[0].row && (
                      <TabPanel>
                        <section className={`pt-20em pb-${gutter}px prl-${gutter / 2}px`}>
                          <Row className={`ptb-10em`}>
                            <Col className={`mc-offset`}>
                              {
                                <h1 className={`mrl-auto section-title fs-18em ls--15em mb-15em`}>
                                  {productSeries.name} Specifications
                                </h1>
                              }
                              {/*
                                <img
                                  src={`/img/tahoma-diagram-1.svg`}
                                  alt="TAHOMA Standard broadcast input boards"
                                />
                              */}
                              <hr className={`alpha-40`} />
                            </Col>
                          </Row>
                          <Row className={`ptb-10em`}>
                            <Col className={`mc-offset`}>
                              {specifications.length > 0 && specifications.map((spec, i) => {
                                logger.debug(`specifications.length`, specifications.length, specifications)
                                let specCols;
                                if (spec.layout === 2) {
                                  specCols = {
                                    xs: 12,
                                    md: 6
                                  };
                                } else if (spec.layout === 3) {
                                  specCols = {
                                    xs: 12,
                                    md: 6,
                                    lg: 4
                                  };
                                } else if (spec.layout === 4) {
                                  specCols = {
                                    xs: 12,
                                    md: 6,
                                    lg: 3
                                  };
                                } else if (spec.layout === 5) {
                                  specCols = {
                                    xs: 12,
                                    md: 6,
                                    lg: 50
                                  };
                                } else if (spec.layout === 12) {
                                  specCols = {
                                    xs: 12
                                  };
                                } else {
                                  specCols = {
                                    xs: 12,
                                    md: 6,
                                    lg: 3
                                  };
                                }
                                let titleIcon;
                                if (spec.icon) {
                                  titleIcon = (<div className={`specIcon`}><img src={`${spec.icon}`} alt={spec.row} /></div>);
                                }
                                return (
                                  <Row key={`spec-${spec.row}`} className={`pt-10em ${specifications.length !== i + 1 ? `pb-10em` : `pb-40em`}`}>
                                    <Col xs={12} md={12} lg={2}>
                                      <h1 className={`fs-12em ls--10em lh-11 mb-10em`}>{spec.row}</h1>
                                      {titleIcon}
                                    </Col>
                                    <Col xs={12} md={12} lg={10}>
                                      <Row>
                                        { spec.data && spec.data.map((val) => {
                                          let item;
                                          if (val.key === `image`) {
                                            item = (
                                              <Col key={`spec-${spec.row}-val-${val.key ? val.key : Math.random()}`} {...specCols} className={`mb-10em`}>
                                                <div className="y-center">
                                                  <img src={val.values[0]} alt={spec.row} />
                                                </div>
                                              </Col>
                                            );
                                          } else {
                                            item = (
                                              <Col key={`spec-${spec.row}--${val.key}-${Math.random()}`} {...specCols} className={`mb-15em`}>
                                                <h4>{val.key}</h4>
                                                { val.values && val.values.map((value) => {
                                                  return <div key={`spec-${spec.row}--${val.key}-${Math.random()}`} className={`fs-095em`}>{value}</div>;
                                                })}
                                              </Col>
                                            );
                                          }
                                          return (item);
                                        })}
                                      </Row>
                                    </Col>
                                    {
                                      specifications.length !== i + 1 && (
                                        <Col xs={12}>
                                          <hr className={`alpha-30`} />
                                        </Col>
                                      )
                                    }
                                  </Row>
                                );
                              })}
                            </Col>
                          </Row>
                        </section>
                      </TabPanel>
                    )
                  )}
                </Tabs>
                { /*
                  productSeries.slider &&
                  productSeries.slider.map((slide) => {
                    return (
                      <img key={`productSlider-${slide}`} src={slide} alt={productSeries.name} />
                    );
                  })
                  */
                }
                {/*
                  { productSeries.products &&
                    productSeries.products.length > 1 && (
                      <section className={`pt-20em pb-${gutter}px prl-${gutter / 2}px`}>
                        <Row className={`ptb-10em`}>
                          <Col xs={12} lg={11} xl={10} className={`mrl-auto`}>
                            <h1 className={`mrl-auto section-title fs-18em ls--15em mb-15em`}>
                              Model comparisson
                            </h1>
                            <hr className={`alpha-40`} />
                            <ProductCompare products={productSeries.products} />
                          </Col>
                        </Row>
                      </section>
                    )
                  }
                  { productSeries.products &&
                    productSeries.products.length > 1 && (
                      <section className={`bg-gray-cold-200 pt-20em pb-30em prl-${gutter / 2}px`}>
                        <Offset>
                          <Row className={`ptb-10em text-center`}>
                            <Col xs={12} lg={11} xl={10} className={`mrl-auto`}>
                              <h1
                                className={`mrl-auto section-title fs-18em ls--15em mb-15em`}
                              >
                                Compatible Accessories
                              </h1>
                              <hr className={`alpha-30 bg-gray-cold-400`} />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} lg={11} xl={10} className={`mrl-auto`}>
                              <Row>
                                { productSeries.products && productSeries.products.map((accessory) => {
                                  return (
                                    accessory && (
                                      <Col
                                        key={`accessory-${accessory.id}`}
                                        lg={50}
                                        className={`prl-${gutter / 2}px pb-${gutter}px`}
                                      >
                                        <CardProduct
                                          keyOf={`accessory-${accessory.id}`}
                                          productLine={productLine && productLine.name}
                                          productGroup={productSeries.productGroup.name}
                                          productLineSlug={productLine.slug}
                                          productGroupSlug={productSeries.productGroup.slug}
                                          {... accessory}
                                        />
                                      </Col>
                                    )
                                  );
                                })}
                              </Row>
                            </Col>
                          </Row>
                        </Offset>
                      </section>
                    )
                  }
                */}
              </Container>
              <section className={`ptb-30em prl-${gutter / 2}px`}>
                <Container fluid>
                  <Row className={`text-center`}>
                    <Col xs={12} lg={11} xl={10} className={`mrl-auto`}>
                      <hr className={`alpha-30 bg-gray-cold-400`} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={11} md={8} lg={7} xl={6} className={`mrl-auto`}>
                      {videoAssets && videoAssets.map((video) => {
                        const playerProps = {
                          url: video.resource.file.url,
                          width: `100%`,
                          height: `100%`,
                          controls: true,
                          playing: true,
                          light: `/img/videoPlaceholder.jpg`,
                          className: `react-player__play-icon`
                        };
                        logger.debug(`videoAsset`, video);
                        return (
                         <div className={`mb-10em`}>
                            <div style={{
                              paddingTop: `56.25%`,
                              position: `relative`
                            }}>
                              <div style={{
                                position: `absolute`,
                                cursor: `pointer`,
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                              }}>
                                <ReactPlayer {...playerProps} />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                </Container>
              </section>
              <section className={`bg-indigo-900 ptb-30em prl-${gutter / 2}px`}>
                <Container fluid>
                  <Row className={`text-center`}>
                    <Col xs={12} lg={11} xl={10} className={`mrl-auto`}>
                      <h1 className={`mrl-auto section-title fs-18em ls--15em mb-15em`}>Downloads & Resources</h1>
                      <hr className={`alpha-30 bg-gray-cold-400`} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={11} lg={11} xl={10} className={`mrl-auto`}>
                      <Row>
                        {downloadTypes && downloadTypes.map((type) => {
                          const label = downloadTypesLabels[type] || <span style={{ textTransform: `capitalize` }}>{type.replace('-', ' ')}</span>;
                          return (
                            <Col key={`downloads-${type}`} md={6} lg={3} xl={50} className={`mb-10em`}>
                              <h1 className="fs-10em fw-400 lh-12 mb-10em">{label}</h1>
                              <hr className={`alpha-30 bg-gray-cold-400`} />
                              <ul className={`related-resources uls-none ml-0 pl-0`}>
                                { downloads.length > 0 && (
                                  downloads.sort((a, b) => {
                                    if (a && b) {
                                      if (a.sort < b.sort) { return -1; }
                                      if (a.sort > b.sort) { return 1; }
                                    }
                                    return 0;
                                  }).sort((a, b) => {
                                    if (a && b) {
                                      if (a.type < b.type) { return -1; }
                                      if (a.type > b.type) { return 1; }
                                    }
                                    return 0;
                                  }).map((download) => {
                                    if (download) {
                                      if (download.resource) {
                                        let downloadType = download.type;
                                        //if (downloadType === `document`) {
                                        //  downloadType = `support`;
                                        //}
                                        let icon;
                                        if (downloadType === `datasheet`) {
                                          icon = `File-Chart`;
                                        } else if (
                                          downloadType === `manual` ||
                                          downloadType === `support`
                                        ) {
                                          icon = `Book`;
                                        } else if (downloadType === `catalog`) {
                                          icon = `Address-Book2`;
                                        } else {
                                          icon = `Download-fromCloud`;
                                        }
                                        let extension = download.resource.file.contentType.split(`/`).pop();
                                        if (extension === `x-dosexec`) { extension = `Executable`; }
                                        if (extension === `octet-stream`) { extension = `Executable`; }
                                        const urlSplit = download.resource.file.url.split('/');
                                        const prettyURL = urlSplit[urlSplit.length - 1];
                                        let name = download.name;
                                        let matches;
                                        let lang;
                                        let language;
                                        let flag;
                                        if (download.name) {
                                          matches = download.name.includes('(') && download.name.split('(');
                                          lang = matches && matches[1].split(')')[0];
                                          if (matches) {
                                            name = matches[0];
                                          }
                                        }
                                        if (lang) {
                                          language = lookup.languages({ name: lang })[0];
                                          if (language) {
                                            flag = lookup.countries({ languages: language.alpha3 })[0] && lookup.countries({ languages: language.alpha3 })[0].alpha2.toLowerCase();
                                            //logger.debug(`language`, language, `flag`, flag);
                                          }
                                        }
                                        // const country = lookup.countries({ alpha2: country })[0] }
                                        let description;
                                            //logger.debug(`download.resource.file`, download.resource.file);
                                        if (download.resource.description) {
                                          description = download.resource.description;
                                        }

                                        return (
                                          download.resource.file && downloadType === type ? (
                                            <li key={`download-${download.id}`} className={`mb-10em`}>
                                              <a
                                                href={`/downloads/files/${prettyURL}`}
                                                alt={download.resource.file.fileName}
                                                target={`_blank`}
                                                className={`color-gray-cold-300 color-white-hover`}
                                              >
                                                <Container>
                                                  <Row>
                                                    <Col xs={1} className={`prl-0`}>
                                                      <i className={`icon icon-${icon} fs-15em mr-5em color-primary-500`} />
                                                    </Col>
                                                    <Col xs={11} className={`pl-10em fs-09em`}>
                                                      <span>
                                                        <b>{name}</b>
                                                        {flag && (<b> <i className={`flag-icon flag-icon-${flag}`} style={{ top: `-1px`, display: `inline-block`, marginLeft: `5px`, fontSize: `12px` }} /></b>)}
                                                      </span>
                                                      <br />
                                                      <span className={`alpha-70 fs-08em tt-uppercase`}>
                                                        {extension.toLowerCase() === 'executable' ? 'Application' : extension}
                                                        <span style={{ top: `-1px`, display: `inline-block`, padding: `0 8px`} }> | </span>
                                                        {numeral(download.resource.file.details.size).format('0.0 b')}
                                                        {flag && language && (
                                                          <span style={{ top: `-1px`, display: `inline-block`, padding: `0 8px`} }> | </span>
                                                        )}
                                                      </span>
                                                      {flag && language && (
                                                        <span className={`alpha-70 fs-08em`}>
                                                          {language.name}
                                                        </span>
                                                      )}
                                                      {description && (
                                                        <span className={`fs-08em mt-05em`}>
                                                          <br />
                                                          <span style={{
                                                            top: `0`,
                                                            fontSize: `13px`,
                                                            lineHeight: `1.2`,
                                                            display: `block`,
                                                            paddingTop: `2px`,
                                                            textTransform: `none !important`,
                                                            color: `rgba(255,255,255,0.5)`
                                                          }}>
                                                            {description}
                                                          </span>
                                                        </span>
                                                      )}
                                                    </Col>
                                                  </Row>
                                                </Container>
                                              </a>
                                            </li>
                                          ) : (``)
                                        );
                                      }
                                    }
                                    return ``;
                                  })
                                )}
                              </ul>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </section>
              {/*
                <section className={`ptb-30em`}>
                  <Offset>
                    <Row>
                      <Col md={4}>
                        <h1>{productSeries.name}</h1>
                        <p className="lead"><b>Product Series</b> Data Test</p>
                      </Col>
                      <Col md={8}>
                        <pre><code>{JSON.stringify(productSeries, null, 2) }</code></pre>
                      </Col>
                    </Row>
                  </Offset>
                </section>
              */}
            </article>
          </Fluid>
        </Layout>
      )
    );
  }
}

export const query = graphql`
  query($slug: String!, $productLineSlug: String) {
    site {
      siteMetadata {
        title
        titleSeparator
      }
    }
    contentfulProductSeries(slug: {eq: $slug}) {
      id
      ... ProductSeries
      ... ProductSeriesSingle
      ... ProductSeriesPresentation
      presentationBlocks {
        id
        title
        layout
        content {
          childMarkdownRemark{
            html
            rawMarkdownBody
          }
        }
        featuredImage{
          fluid(quality: 30 maxWidth: 800) {
            base64
            aspectRatio
            src
            srcSet
            sizes 
          }
        }
      }
      platformBoardsTab
      slider{
        fluid(quality: 30 maxWidth: 800) {
          base64
          aspectRatio
          src
          srcSet
          sizes 
        }
        ... AssetFileDetails
      }
    }
    contentfulProductLine(slug: {eq: $productLineSlug}) {
      name
      fields{
        uniqueDownloads{
          id
          name
          type
          resource {
            ... DownloadFile
          }
          related{
            __typename
          }
        }
      }
    }
    allContentfulProductLine(sort: { fields: [sort], order: DESC } ){
      edges{
        node{
          id
          ... ProductLineBase
        }
      }
    }
    allContentfulMenu(filter:{location: {regex: "/Products Categories|Products Other Categories|Products Lines/"}}){
      edges{
         node{
          name
          location
          fields{
            links{
              id
              name
              title
              parentPage{
                slug
              }
              internal{
                type
              }
              slug
              contentTypes
            }
          }
        }
      }
    }
    allContentfulCategory(
      filter: {
        contentTypes: {
          in: ["productGroups"]
        }
      }
    ){
      edges{
        node{
          ... Category
        }
      }
    }
  }
`;

export default ProductSeriesSingle;
