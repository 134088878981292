import React from 'react';
import classNames from 'classnames';
import { StaticQuery, Link, graphql, navigate } from 'gatsby';
import { Fluid, Offset } from 'components/Containers';
import { Col, Container, Row } from 'reactstrap';
import logger from 'utils/logger';
import ReactSVG from 'react-svg'

class Component extends React.Component {
  render () {
    const { data } = this.props;
    logger.gql(data);
    if (!data || !data.allContentfulTPlatformBoards) { return <LoadingView />; }
    // const networks = this.props.networks;
    const boards = data.allContentfulTPlatformBoards.edges;
    logger.verbose(`boards`, boards);

    return (
      <section id="boards">
        <Offset>
          <Row className={`pt-30em pb-0`}>
            <Col xs={12} className={`mrl-auto`}>
              <h1 className={`mrl-auto section-title fs-18em ls--15em mb-0`}>Platform Boards</h1>
            </Col>
          </Row>
          <Row className={`pb-30em`}>
            <Col xs={12} className={`mrl-auto`}>
              { boards.map((edge) => {
                const board = edge.node;
                return (
                  <Row key={`productSeries-boards-${board.id}`} className={`pt-10em align-items-center`}>
                    <Col xs={12} className={`mb-15em`} >
                      <hr className={`alpha-30`} />
                    </Col>
                    <Col xs={12} lg={6}>
                      <div>
                      {/*
                        <ReactSVG
                          path={board.image && board.image.file.url}
                          callback={svg => svg.setAttribute('preserveAspectRatio', 'xMinYMin')}
                          className="reactsvg"
                          wrapperClassName="reactsvg-wrapper"
                        />
                      */}
                        <div className={`svg-wrapper`}>
                          <img
                            preserveAspectRatio="none"
                            src={board.image && board.image.file.url}
                            alt={board.name}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} lg={6}>
                      <h3 className={`ls--10em mb-10em`}>{board.tagline}</h3>
                      {board.description.childMarkdownRemark.html && <div className={`product-keyFeature fs-10em lh-16`} dangerouslySetInnerHTML={{ __html: board.description.childMarkdownRemark.html }} />}
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Offset>
      </section>
    );
  }
}

const query = graphql`
  query {
    allContentfulTPlatformBoards {
      edges {
        node {
          ... Board
        }
      }
    }
  }
`;

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={data => (
      <>
        <Component data={data}/>
      </>
    )}
  />
);
